import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function () {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");
  require("./iframe-resizer");

  $(".gallery").each(function () {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $(document).ready(function () {
    $('.management__slider').slick({
      dots: false,
      infinite: true,
      speed: 2000,
      fade: true,
      cssEase: 'linear',
      arrows: false,
      autoplay: true,
      pauseOnHover: false
    });
  });

});
